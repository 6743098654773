<template>
    <div class="wrapper">
        <div class="top">
            <Language/>
        </div>
        <div class="bottom">
            <WeChatQRCodeForm @callback="callback">
                <template slot="title">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.weChatScanLogin')}}</span>
                </template>
                <template slot="back">
                    <el-row>
                        <el-col style="text-align: left;margin-top: -120px;margin-left: -260px;">
                            <el-button
                                    style="border: 0px; background-color: rgb(237, 237, 237);"
                                    @click="() =>{
                                this.$router.replace('/login');
                            }"
                                    icon="el-icon-d-arrow-left" round>
                                {{$t('login.return')}}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
                <template slot="buttom">
                    {{$t('login.scanCodePublicLogin')}}
                </template>
                <template slot="link">
                    <ThirdPartyLogin :accountLogin="true" :mobile-login="true"/>
                </template>
            </WeChatQRCodeForm>
        </div>
    </div>
</template>

<script>
    import CommonLogin from "../../../utils/CommonLogin";
    import Language from "../../../components/login/Language";
    import WeChatQRCodeForm from "../../../components/login/WeChatQRCodeForm";
    import ThirdPartyLogin from "../../../components/login/ThirdPartyLogin";

    export default {
        name: "WeChatQRCode",
        components: {ThirdPartyLogin, WeChatQRCodeForm, Language},
        data() {
            return {}
        },
        mounted() {

        },
        methods: {
            callback(result) {
                if (result) {
                    this.$router.replace("/enterpriseInformation");
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";

    @input-width: 400px;

    .wrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .we-chat-qrcode-icon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 32px;
        color: white;

        &:hover {
            transition: 0.3s;
            opacity: 0.8;
        }
    }
</style>
